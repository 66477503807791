import PropTypes from "prop-types"
import { Link } from "gatsby"
import React from "react"
import Headroom from "react-headroom"
import { Navbar, Nav } from "react-bootstrap"
import LogoHeader from "../image-components/logo-header"
import LogoSidebar from "../image-components/logo-sidebar"

const Header = ({ siteTitle, menuLinks }) => (
  <React.Fragment>
    <Headroom>
    <Navbar bg="dark" variant="dark" className="d-lg-none">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <div className="d-inline-block align-top mr-2" style={{ height: `32px`, width: `32px` }}>
            <LogoHeader />
          </div>
          {siteTitle}
        </Link> 
        <Nav className="ml-auto">
          {menuLinks.map(link => (
            <Link className="nav-item nav-link" partiallyActive={true} activeClassName="active" key={link.name} to={link.link}>{link.name}</Link>
          ))}
        </Nav>
      </div>
    </Navbar>
    </Headroom>
    <div className="navbar navbar-dark d-none col-3 bg-dark d-lg-flex flex-column justify-content-center align-items-center" style={sidebarStyle}>
      <Nav className="navbar-nav d-flex flex-column align-items-center">
        <Link className="nav-item nav-link d-flex flex-column align-items-center my-1" activeClassName="active" to="/">
          <div style={{ height: `128px`, width: `128px` }}>
            <LogoSidebar/>
          </div>
          <div className="mt-1">{siteTitle}</div>
          <small>Mindful Technologist</small>
          <small>Martinez, CA</small>
        </Link>
        {menuLinks.map(link => (
          <Link className="nav-item nav-link text-center w-100 my-1" partiallyActive={true} activeClassName="active" key={link.name} to={link.link}>{link.name}</Link>
        ))}
      </Nav>
    </div>
  </React.Fragment>
)

const sidebarStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  menuLinks: PropTypes.array
}

Header.defaultProps = {
  siteTitle: ``,
  menuLinks: []
}

export default Header
